var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-8"},[_c('title-slot',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Payment ")]},proxy:true}])}),_c('v-row',[(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12","md":_vm.isAdmin ? 6 : 0}},[_c('v-btn',{staticClass:"text-capitalize py-2",class:{
          'font-weight-medium': _vm.buttonOne === _vm.activeButton,
          'font-weight-regular': _vm.buttonOne !== _vm.activeButton,
        },attrs:{"depressed":"","block":"","color":_vm.buttonOne === _vm.activeButton ? 'primary' : 'lightBg',"height":"100%"},on:{"click":function($event){return _vm.assignActiveButton(_vm.buttonOne)}}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"white","size":"30"}},[_c('v-icon',{attrs:{"color":"primary","dense":""}},[_vm._v("mdi-cash")])],1),_vm._v("Cash")],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":_vm.isAdmin ? 6 : 0}},[_c('v-btn',{staticClass:"font-weight-regular text-capitalize py-2",class:{
          'font-weight-medium': _vm.buttonTwo === _vm.activeButton,
          'font-weight-regular': _vm.buttonTwo !== _vm.activeButton,
        },attrs:{"depressed":"","block":"","color":_vm.buttonTwo === _vm.activeButton ? 'primary' : 'lightBg',"height":"100%"},on:{"click":function($event){return _vm.assignActiveButton(_vm.buttonTwo)}}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"white","size":"30"}},[_c('v-img',{attrs:{"contain":"","width":"20","height":"20","src":_vm.imgSrc}})],1),_vm._v("GCash")],1)],1)],1),_c('div',{staticClass:"mt-4"},[_c('label-slot',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Amount Received ")]},proxy:true}])}),_c('v-text-field',{attrs:{"type":"number","dense":"","hide-details":"auto","outlined":"","rules":_vm.rules.amountReceived},on:{"change":_vm.emitTransaction},model:{value:(_vm.payload.payment.amountReceived),callback:function ($$v) {_vm.$set(_vm.payload.payment, "amountReceived", _vm._n($$v))},expression:"payload.payment.amountReceived"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }