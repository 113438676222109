import { render, staticRenderFns } from "./TransactionsTable.vue?vue&type=template&id=23bf5a16&scoped=true"
import script from "./TransactionsTable.vue?vue&type=script&lang=js"
export * from "./TransactionsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23bf5a16",
  null
  
)

export default component.exports