var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"bg-color",class:{
    'image-bg': _vm.$router.currentRoute.meta.name === 'Sign In',
    'image-bg-2': _vm.$router.currentRoute.meta.name === 'Guest Sign In',
  }},[_c('div',{staticClass:"container-height"},[(_vm.$auth.user()?.role === 'ADMIN')?_c('Navigation'):(
        _vm.$router.currentRoute.meta.isPublic ||
        _vm.$router.currentRoute.meta.isGuest
      )?_c('PublicNavigation'):_vm._e(),_c('div',{class:{
        'flex-grow-1 mt-n10 bg-color main-layout white py-5': !_vm.notAllowedRoutes.includes(
          _vm.$router.currentRoute.name
        ),
      }},[_c('v-container',{staticClass:"pa-0 transparent-bg"},[_c('v-main',{staticClass:"mx-3",class:{
            'custom-main': _vm.notAllowedRoutes.includes(
              _vm.$router.currentRoute.name
            ),
          }},[_c('router-view')],1)],1)],1),(_vm.allowedFooterRoutes.includes(_vm.$router.currentRoute.name))?_c('footer-component'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }