import { render, staticRenderFns } from "./RoomReservationForm.vue?vue&type=template&id=2ec78201&scoped=true"
import script from "./RoomReservationForm.vue?vue&type=script&lang=js"
export * from "./RoomReservationForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec78201",
  null
  
)

export default component.exports